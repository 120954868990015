import React from "react"
import "./SponsorComponentStyle.scss"
import RollerDerbyHuseImage from "../../assets/image/rollerderbyhouse.png"
import DevDuckImage from "../../assets/image/devduck.png"
import PodioImage from "../../assets/image/podio.png"

const SponsorComponent = () => {
  return (
    <div className="sponsor-component-wrapper" id="sponsors">
      <h2>WE LOVE OUR SPONSORS</h2>
      <div className="sponsor-component-entry-wrapper">
        <div className="sponsor-image-wrapper">
          <a
            href="https://www.rollerderbyhouse.eu/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={RollerDerbyHuseImage}
              style={{ maxWidth: "80px" }}
              alt="Roller Derby House"
            />
          </a>
        </div>
        <div className="sponsor-image-wrapper">
          <a
            href="https://www.devduck.de"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={DevDuckImage} alt="DevDuck" />
          </a>
        </div>
        <div className="sponsor-image-wrapper">
          <a
            href="https://company.podio.com/crm-software"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={PodioImage} alt="Podio" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default SponsorComponent
