import React from "react"
import "./NewsComponentStyle.scss"
import NewsData from "./data.json"
import { Link } from "gatsby"
import EmptyImage from "../../assets/image/bcrd_placeholder.png"

const NewsComponentEntry = ({
  data,
  isEnglish,
}: {
  data: any
  isEnglish?: boolean
}) => {
  return (
    <div className="news-component-entry">
      <Link to={isEnglish ? "/en/news/" + data.slug : "/news/" + data.slug}>
        <div className="news-component-entry-content-wrapper">
          <div className="news-component-entry-image">
            {data.image === "" ? (
              <img src={EmptyImage} />
            ) : (
              <img src={"/news/images/" + data.image} />
            )}
          </div>
          <div className="news-component-entry-text">
            <p className="news-component-entry-title">{data.title}</p>
            <p className="news-component-entry-subtitle">
              {isEnglish ? data.subtitleEN : data.subtitle}
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
}

const NewsComponent = ({ isEnglish }: { isEnglish?: boolean }) => {
  return (
    <div className="news-component-wrapper" id="bouts">
      <h2>
        <Link to={isEnglish ? "/en/news" : "/news"}>BOUTS and EVENTS</Link>
      </h2>
      <div className="news-component-entry-wrapper">
        {NewsData[0] && (
          <NewsComponentEntry isEnglish={isEnglish} data={NewsData[0]} />
        )}
        {NewsData[1] && (
          <NewsComponentEntry isEnglish={isEnglish} data={NewsData[1]} />
        )}
        {NewsData[2] && (
          <NewsComponentEntry isEnglish={isEnglish} data={NewsData[2]} />
        )}
      </div>
    </div>
  )
}

export default NewsComponent
